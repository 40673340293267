export function slugify(str) {
  return str.toLowerCase().replace(/\s/g, '-');
}

export const markers = [
  {
    name: 'David & Fils',
    latlng: [50.6912128,4.1744382],
    address: 'Rue Try-Bas 116, 1480 Tubize'
  }
];

export const mapConfig = {
  center: [50.6912128,4.1744382],
  zoom: 9
};