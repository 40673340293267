import React, { Component } from 'react'
import ReactLeafletMap from './ReactLeafletMap';
// Style
// import '../Historique.css'


class PlanAcces extends Component {
  render () {
    return (
    	<div className="plan-acces-main row no-gutters">
        <div className="col-md-12">
          <h1 className="sfont text-center mb-5 page-title">Plan d'accès</h1>
          <ReactLeafletMap/>
        </div>
		  </div>
    )
  }
}

export default PlanAcces;