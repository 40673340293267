import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Contact from './Contact';
import PlanAcces from './PlanAcces'
import Historique from './Historique'
import Home from './Home';



class Main extends Component {
  
  getPathName = path => {
    if (path === '/') {
      return 'home'
    } 
    return path.replace('/','')
  }

	render() {
    return (
      <div className={'bg-cont ' + this.getPathName(this.props.location.pathname)}>
        <main className="main-container container">
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route path='/contact' component={Contact}/>
            <Route path='/plan_acces' component={PlanAcces}/>
            <Route path='/historique' component={Historique}/>
          </Switch>
        </main>
      </div>
		)
	}
}

export default withRouter(Main);