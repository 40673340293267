import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import '../css/vendor/swiper.min.css';
import escalierdavid_logo_img from '../img/escalierdavid_logo_img.svg';
import img1 from '../img/escalier_marbre_granito/_1.jpg';
import img2 from '../img/escalier_marbre_granito/_2.jpg';
import img3 from '../img/escalier_marbre_granito/_3.jpg';
import img4 from '../img/escalier_marbre_granito/_4.jpg';

// import {Icon} from 'react-fa';

class Home extends Component {
	render() {
    const swiperParams = {
      setWrapperSize: true,
      autoHeight: false,
      height:300,
      autoplay: true,
      loop:true,
      keyboard: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      themeColor: '#007aff',
      spaceBetween: 30
    }


		return (
			<div className="home-cont">
        <h1 className="hey-there sfont">Escaliers pour la vie</h1>
        <p className="slogan-description text-center">Escaliers, Agglomérés de marbre, Marbre naturels, Granit</p>
        <img className="home-escalierdavid-logo" src={escalierdavid_logo_img} alt="escalier david logo"/>
        <div className="mb-5 home-carousel-cont">
          <Swiper {...swiperParams} className="mb-5">
            <div><img src={img2} alt="escalier david 1" /></div>
            <div><img src={img1} alt="escalier david 2" /></div>
            <div><img src={img3} alt="escalier david 3" /></div>
            <div><img src={img4} alt="escalier david 4" /></div>
          </Swiper>
        </div>
        <p className="home-excerpt">Tout a commencé à la fin de la 1<sup>ère</sup> Guerre Mondiale, quand mon Grand Père «David Augusto» a du quitter l’Italie pour venir chercher du travail comme salarié en Belgique. <Link to="/historique">Lire la suite &#8250;</Link></p>


			</div>
		)
	}
}

export default Home;