import React, { Component } from 'react';
import facebook from '../img/facebook.png';
import email from '../img/email.png';
import phone from '../img/phone.png';

class Footer extends Component {
	render() {
		return(
			<footer className="footer">
				<ul className="social-links">
          <li><a href="tel:+32475697406"><img src={phone} alt="Appelle Escalier David Guerino"/></a></li>
					<li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/David-fils-338427836201106/"><img src={facebook} alt="Facebook David &amp; fils"/></a></li>
					<li><a href="mailto:ets.david.fils@skynet.be"><img src={email} alt="email ets.david.fils@skynet.be"/></a></li>
				</ul>
			</footer>
		)
	}
}

export default Footer;