import React, { Component } from 'react'
import escalierdavid_logo_img from '../img/escalierdavid_logo_img.svg';

// Style
// import '../Historique.css'


class Historique extends Component {
  render () {
    return (
    	<div className="contact-main">
        <h1 className="sfont text-center mb-5 page-title">Histoire de l'entreprise</h1>
				<div className="history_box default-text">
        <p>Tout a commencé à la fin de la 1<sup>ère</sup> Guerre Mondiale, quand mon Grand Père «David Augusto» a du quitter l’Italie pour venir chercher du travail comme salarié en Belgique.</p>
 
        <p>Il est donc venu fabriquer des escaliers en granito, des façades, des sols coulés sur place également appelé à l’époque TERRAZZO.</p>
         
        <p>Ensuite, mon père « David Serjio » qui a appris le métier de son père, créa sa propre société en 1962.</p>
         
        <p>Celui-ci se spécialisa uniquement dans les escaliers en préfabriqués toujours en granito pour avoir un plus bel aspect, une meilleure finition. Il créa également des veinés (imitation marbre).</p>
         
        <p>Enfin, j’ai repris la société de mon père en 1990 en élargissant la diversité des matières telle que le marbre, la pierre bleue, le granit, provenant du monde entier.</p>
        </div>
        <img className="home-escalierdavid-logo" src={escalierdavid_logo_img} alt="escalier david logo"/>
		</div>
    )
  }
}

export default Historique;