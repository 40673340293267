import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Typist from 'react-typist';

import { Affix } from 'react-overlays'; // AutoAffix
// import escalierdavid_logo_img from '../img/escalierdavid_logo_img.svg';
import escalierdavid_logo from '../img/escalierdavid_logo.svg';


class Header extends Component {
  constructor(propr,context) {
    super(propr,context);

    this.state = {
      isMenuOpen: false
    }
  }

  toggleMenu = e => {
    if (this.state.isMenuOpen) {
      this.setState({isMenuOpen:false})
    }
    else {
      this.setState({isMenuOpen:true})
    }
  }

  closeMenu = () => {
    this.setState({isMenuOpen:false})
  }

  menuLink = e => {
    this.setState({isMenuOpen:false})
  }

	render() {
    

		return (
			<Affix affixClassName="affix">
				
					<header className="app-header clearfix sfont">
            <div className="header-cont">
              <Link className="logo-escalierdavid" to="/">
                <img src={escalierdavid_logo} alt="escalier david logo 2"/>
              </Link>
              <div className="menu-trigger-cont">
                <div className="menu-trigger" onClick={this.toggleMenu}>Menu</div>
              </div>

							{/* <Link className="logo-escalierdavid-image" to="/">
                <img src={escalierdavid_logo_img} alt="escalier david logo"/>
              </Link> */}


              <h1 className="my-name d-none">Escalier David</h1>

              <nav className={'main-nav ' + (this.state.isMenuOpen ? 'open' : '')}>
                <div className="close-panel" onClick={this.closeMenu}>x</div>
                <ul>
                  <li><Link to="/contact" onClick={this.menuLink}>Contact</Link></li>
                  <li><Link to="/historique" onClick={this.menuLink}>Historique</Link></li>
                  <li><Link to="/plan_acces" onClick={this.menuLink}>Plan d'accès</Link></li>
                </ul>
              </nav>
            </div>
            <div className={'overlay-transparent ' + (this.state.isMenuOpen ? 'visible' : '')} onClick={this.closeMenu}>&nbsp;</div>
					</header>
				
			</Affix>
		)
	}
}

export default Header;