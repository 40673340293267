import React, { Component } from 'react'
import escalierdavid_logo_img from '../img/escalierdavid_logo_img.svg';

import axios from 'axios';
import Validator from 'validatorjs'

// Maps

// Material-UI
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'
import Snackbar from 'material-ui/Snackbar'

// Theme
import {
  green700, grey100, grey300, grey400, grey500, white, darkBlack, fullBlack,
} from 'material-ui/styles/colors'
import {fade} from 'material-ui/utils/colorManipulator';
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

// Style
import '../Contact.scss'

// Click handler
// import injectTapEventPlugin from 'react-tap-event-plugin'
// injectTapEventPlugin()


// define payload structure
const payload = {
  data: {
    type: "contacts",
    "attributes": {}
  }
}

// Styles
const styles = {
 	fieldContainer 		: { 'width' : '100%' , 'fontFamily': "'Thasadith', sans-serif"},
 	snackBar 			: { 'fontFamily': "'Thasadith', sans-serif", 'textAlign' : 'center'}
}

// Theme
const muiTheme = getMuiTheme({
	palette: {
		primary1Color: '#D58248',
		primary2Color: green700,
		primary3Color: grey400,
		accent1Color: '#D58248',
		accent2Color: grey100,
		accent3Color: grey500,
		textColor: darkBlack,
		alternateTextColor: white,
		canvasColor: white,
		borderColor: grey300,
		disabledColor: fade(darkBlack, 0.3),
		pickerHeaderColor: '#D58248',
		clockCircleColor: fade(darkBlack, 0.07),
		shadowColor: fullBlack,
	},
	fontFamily: "'Thasadith', sans-serif",
	snackbar: { actionColor: '#666' },
})

const rules = {
	username: 'required',
	email: 'required|email',
	message: 'required|max:3000'
};

class Contact extends Component {
	constructor (props, context) {
		super(props, context)

		// Default text
		this.state = {
			fields: {
				username: 		'',
				email: 		'',
				message: 	'',
			},
			errors: {
				username: 		'',
				email: 		'',
				message: 	''
			},
			loadingMessage: 			'Envoi...',
			successMessage: 			'Message envoyé',
			errorMessage: 				'Une erreur s\'est produite, veuillez réessayer plus tard!',
			showSnackBar: 				false,
      snackBarCurrentMessage: 	'',
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
		}
	}

	closeSnackBar = () => {
		this.setState({showSnackBar:false})
	}

	validateField(name) {
    
		const field = {[name]: this.state.fields[name]};
		const fieldRules = {[name]: rules[name]};
    const errors = this.state.errors
  

		// console.log('field: ' , field)
		// console.log('fieldRules: ' , fieldRules)

		let validation = new Validator(field, fieldRules);

		const hasError = validation.fails();
		if (hasError) errors[name] = validation.errors.first(name)
		else errors[name] = ''

		// console.log('validation:', validation)
  }

	onSubmit = e => {
		e.preventDefault()


		// validation
		let data = this.state.fields
		let validation = new Validator(data, rules);

		const isFormValid = validation.passes();

		if (isFormValid) {
			this.setState({snackBarCurrentMessage: this.state.loadingMessage})
			this.setState({showSnackBar: true})

			const {username, email, message} = this.state.fields;
      payload.data.attributes = {username, email, message}
      
      var bodyFormData = new FormData();

      bodyFormData.set('username', this.state.fields.username);
      bodyFormData.set('email', this.state.fields.email);
      bodyFormData.set('message', this.state.fields.message);


			axios({
				method:'POST',
        url:'https://www.escalierdavid.be/wp/index.php?rest_route=/contact-form-7/v1/contact-forms/5/feedback', // https://www.escalierdavid.be/wp/wp-json/contact-form-7/v1/contact-forms/5/feedback', //'http://localhost:8765/api/contacts.json',
				// headers: {
				// 	'Accept': 'application/vnd.api+json',
				// 	'Content-Type': 'application/vnd.api+json'
				// },
				data: bodyFormData
			})
			.then( response => {
				this.setState({isLoading: false})

        console.log('response.data: ' , response.data)
				const successMessage = response.data.message;
				this.setState({snackBarCurrentMessage: successMessage})
				this.setState({showSnackBar: true})
				// toast.success(successMessage, toastOpts)

				const fields = {
					username: 		'',
					email: 		'',
					message: 	''
				}

				this.setState({fields})

			},
			error => {
				this.setState({isLoading: false})

				const errorMessage = 'Une erreur s\'est produite, veuillez réessayer plus tard';
				this.setState({showSnackBar: true})
				this.setState({snackBarCurrentMessage: errorMessage})
				// toast.error(errorMessage, toastOpts)
			});
			// console.log('isFormValid')

		}
		else {
			const errors = this.state.errors;
			for (var key in errors) {
				errors[key] = validation.errors.first(key)
			}

			this.setState({errors: errors})
		}
		// console.log('validation: ', validation)
	}

  onChange = e => {
  	const name = e.target.name;
  	const value = e.target.value;

  	const fields = this.state.fields;
  	fields[name] = value;
  	this.setState({fields})
  	this.validateField(name);

  	var errors = {...this.state.errors}
    this.setState({errors})
  }

  render () {
    
    return (
    	<div className="contact-main">
			<MuiThemeProvider muiTheme={muiTheme}>
				<div className="row">
					<div className="col-md-12 other-methods-container">
						<h3 className="sfont">Contact direct</h3>

						<div className="contact-label">Téléphone portable</div>
						<a className="phone-link" href="tel:+32475697406">0475/69.74.06</a>
            <div className="contact-label">Téléphone</div>
            <a className="phone-link" href="tel:+3223558217">02/355.82.17</a>
            <div className="contact-label">Fax</div>
            <a className="phone-link" href="tel:+3223558217">02/355.82.17</a>
						<div className="contact-label">Email</div>
						<a className="email-link" href="mailto:ets.david.fils@skynet.be">ets.david.fils@skynet.be</a>

					</div>

					<div className="col-md-12 contact-form-container">
						<form onSubmit={this.onSubmit}>
							<TextField
								name='username'
								ref='username'
								floatingLabelText='Nom'
								value={this.state.fields.username}
								errorText={this.state.errors.username}
								onChange={this.onChange}
								style={styles.fieldContainer}
								className="field-container"
							/>
							<TextField
								name='email'
								ref='email'
								floatingLabelText='Email'
								value={this.state.fields.email}
								errorText={this.state.errors.email}
								onChange={this.onChange}
								style={styles.fieldContainer}
								className="field-container"
							/>
							<TextField
								multiLine={true}
								rows={4}
								name='message'
								ref='message'
								floatingLabelText='Saisissez votre message'
								value={this.state.fields.message}
								errorText={this.state.errors.message}
								onChange={this.onChange}
								style={styles.fieldContainer}
								className="field-container"
							/>
							<RaisedButton type='submit' label='Envoyer' ref="submit" primary />
						</form>
					</div>
					
          <Snackbar
            style={styles.snackBar}
            ref="snackbar"
            message={this.state.snackBarCurrentMessage}
            open={this.state.showSnackBar}
            // autoHideDuration={80000000}
            onRequestClose={this.closeSnackBar}
          />
				</div>
			</MuiThemeProvider>
      <img className="home-escalierdavid-logo" src={escalierdavid_logo_img} alt="escalier david logo"/>
		</div>
    )
  }
}

export default Contact;